/* eslint-disable */
// https://dev2.bhsn.it/api/alex/swagger-ui/index.html
import type { AxiosPromise, AxiosInstance } from 'axios';
import { toAxiosOptions, mergeObjects } from '@allibee/utils';

let axiosInstance: AxiosInstance;
let baseURL: string;

const serviceRoute = 'alex';
export function setupServiceInstance(callback: (serviceRoute: string) => { baseURL: string; instance: AxiosInstance }) {
    const result = callback(serviceRoute);
    axiosInstance = result.instance;
    baseURL = result.baseURL;
}

export function getBaseURL() {
    return baseURL;
}

/**
 * 체결본 Bulk 요청 참조자 수정 V1
 * @returns AlexResponse OK
 * @throws ApiError
 */
export function updateContractBulkRequestWatchers(
    {
        requestBody,
    }: {
        requestBody: AlexContractBulkRequestUsersUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AlexResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/contracts/bulk-requests/users/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 체결본 Bulk 요청 Retry V1
 * @returns AlexResponse OK
 * @throws ApiError
 */
export function retryContractBulkRequest(
    {
        requestBody,
    }: {
        requestBody: AlexContractBulkRequestRetryForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AlexResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/contracts/bulk-requests/retry/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 체결본 Bulk 등록 시작 V1
 * @returns AlexResponse OK
 * @throws ApiError
 */
export function registerContractBulkRequest(
    {
        requestBody,
    }: {
        requestBody: AlexContractBulkRequestRegisterForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AlexResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/contracts/bulk-requests/register/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 체결본 Bulk 요청 소유 부서 수정 V1
 * @returns AlexResponse OK
 * @throws ApiError
 */
export function updateContractBulkRequestGroup(
    {
        requestBody,
    }: {
        requestBody: AlexContractBulkRequestGroupUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AlexResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/contracts/bulk-requests/groups/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 체결본 Bulk 요청 확인 V1
 * @returns AlexResponse OK
 * @throws ApiError
 */
export function confirmContractBulkRequest(
    {
        requestBody,
    }: {
        requestBody: AlexContractBulkRequestConfirmForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AlexResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/contracts/bulk-requests/confirm/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 체결본 일괄 업로드 조회
 * @returns AlexResponseListContractBulkRequestDto OK
 * @throws ApiError
 */
export function getContractBulkRequests(
    {
        executionStatus,
    }: {
        /**
         * 체결본 상태
         */
        executionStatus?: string;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AlexResponseListContractBulkRequestDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/bulk-requests/v1',
                query: {
                    executionStatus: executionStatus,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 체결본 Bulk 요청 생성 V1
 * @returns AlexResponse OK
 * @throws ApiError
 */
export function createContractBulkRequest(
    {
        requestBody,
    }: {
        requestBody: AlexContractBulkRequestCreateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AlexResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/contracts/bulk-requests/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 체결본 Bulk 요청 삭제 V1
 * @returns AlexResponse OK
 * @throws ApiError
 */
export function deleteContractBulkRequest(
    {
        requestBody,
    }: {
        requestBody: AlexContractBulkRequestDeleteForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AlexResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/contracts/bulk-requests/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 체결본 Bulk 요청 - 다운로드 V1
 * @returns AlexResponse OK
 * @throws ApiError
 */
export function downloadByContractBulkRequestId(
    {
        requestId,
    }: {
        requestId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AlexResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/bulk-requests/{requestId}/download/v1',
                path: {
                    requestId: requestId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 체결본 Bulk 요청 상태별 카운트 V1
 * @returns AlexResponseContractBulkRequestCountDto OK
 * @throws ApiError
 */
export function countContractBulkRequest(_?: any, requestConfig?: RequestConfig): AxiosPromise<AlexResponseContractBulkRequestCountDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/bulk-requests/count-by-status/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * id 범위의 계약서를 Elasticsearch에 재색인하기 V1
 * @returns AlexResponse OK
 * @throws ApiError
 */
export function reindexByIdRange(
    {
        idStart,
        idEnd,
    }: {
        idStart: number;
        idEnd: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AlexResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/reindex/id-range/v1',
                query: {
                    idStart: idStart,
                    idEnd: idEnd,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 전체 계약서를 Elasticsearch에 재색인하기 V1
 * @returns AlexResponse OK
 * @throws ApiError
 */
export function reindexAll(_?: any, requestConfig?: RequestConfig): AxiosPromise<AlexResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/reindex/all/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 체결본 관할 법원 수정
 * @returns AlexResponse OK
 * @throws ApiError
 */
export function updateContractVenue(
    {
        contractId,
        requestBody,
    }: {
        contractId: number;
        requestBody: AlexContractVenueUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AlexResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/contracts/{contractId}/venue/v1',
                path: {
                    contractId: contractId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 체결본 수정하기 V2
 * @returns AlexResponse OK
 * @throws ApiError
 */
export function updateContractV2(
    {
        contractId,
        requestBody,
    }: {
        contractId: number;
        requestBody: AlexContractUpdateFormV2;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AlexResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/contracts/{contractId}/v2',
                path: {
                    contractId: contractId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 체결본 참조자 수정
 * @returns AlexResponse OK
 * @throws ApiError
 */
export function updateContractUserIds(
    {
        contractId,
        requestBody,
    }: {
        contractId: number;
        requestBody: AlexContractUsersUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AlexResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/contracts/{contractId}/users/v1',
                path: {
                    contractId: contractId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 체결본 제목 수정
 * @returns AlexResponse OK
 * @throws ApiError
 */
export function updateContractTitle(
    {
        contractId,
        requestBody,
    }: {
        contractId: number;
        requestBody: AlexContractTitleUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AlexResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/contracts/{contractId}/title/v1',
                path: {
                    contractId: contractId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 체결본 계약 시작 / 종료일 수정
 * @returns AlexResponse OK
 * @throws ApiError
 */
export function updateContractStartAndEndDate(
    {
        contractId,
        requestBody,
    }: {
        contractId: number;
        requestBody: AlexContractStartAndEndDateUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AlexResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/contracts/{contractId}/start-end-date/v1',
                path: {
                    contractId: contractId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 체결본 간단 수정하기 V1
 * @returns AlexResponse OK
 * @throws ApiError
 */
export function patchContractSimpleV1(
    {
        contractId,
        requestBody,
    }: {
        contractId: number;
        requestBody: AlexContractSimpleUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AlexResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/contracts/{contractId}/simple/v1',
                path: {
                    contractId: contractId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * @deprecated
 * 체결본 간단 수정하기 V1
 * @returns AlexResponse OK
 * @throws ApiError
 */
export function patchContractSimple(
    {
        contractId,
        requestBody,
    }: {
        contractId: number;
        requestBody: AlexContractSimpleUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AlexResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PATCH',
                url: '/contracts/{contractId}/simple/v1',
                path: {
                    contractId: contractId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 체결본 계약 체결일 수정
 * @returns AlexResponse OK
 * @throws ApiError
 */
export function updateContractSignedDate(
    {
        contractId,
        requestBody,
    }: {
        contractId: number;
        requestBody: AlexContractSignedDateUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AlexResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/contracts/{contractId}/signed-date/v1',
                path: {
                    contractId: contractId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 체결본 자사 지위 수정
 * @returns AlexResponse OK
 * @throws ApiError
 */
export function updateContractOurPosition(
    {
        contractId,
        requestBody,
    }: {
        contractId: number;
        requestBody: AlexContractOurPositionUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AlexResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/contracts/{contractId}/our-position/v1',
                path: {
                    contractId: contractId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 체결본 계약 메모 수정
 * @returns AlexResponse OK
 * @throws ApiError
 */
export function updateContractMemo(
    {
        contractId,
        requestBody,
    }: {
        contractId: number;
        requestBody: AlexContractMemoUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AlexResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/contracts/{contractId}/memo/v1',
                path: {
                    contractId: contractId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 체결본 준거법 수정
 * @returns AlexResponse OK
 * @throws ApiError
 */
export function updateContractGoverningLaw(
    {
        contractId,
        requestBody,
    }: {
        contractId: number;
        requestBody: AlexContractGoverningLawUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AlexResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/contracts/{contractId}/governing-law/v1',
                path: {
                    contractId: contractId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 체결본 분쟁해결 방법 수정
 * @returns AlexResponse OK
 * @throws ApiError
 */
export function updateContractDisputeResolutionMethod(
    {
        contractId,
        requestBody,
    }: {
        contractId: number;
        requestBody: AlexContractDisputeResolutionMethodUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AlexResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/contracts/{contractId}/dispute-resolution-method/v1',
                path: {
                    contractId: contractId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 체결본 계약 상대방 수정
 * @returns AlexResponse OK
 * @throws ApiError
 */
export function updateContractCounterparties(
    {
        contractId,
        requestBody,
    }: {
        contractId: number;
        requestBody: AlexContractCounterpartiesUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AlexResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/contracts/{contractId}/counterparties/v1',
                path: {
                    contractId: contractId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 체결본 계약 금액 / 통화 수정
 * @returns AlexResponse OK
 * @throws ApiError
 */
export function updateContractAmountAndCurrency(
    {
        contractId,
        requestBody,
    }: {
        contractId: number;
        requestBody: AlexContractAmountAndCurrencyUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AlexResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/contracts/{contractId}/amount-currency/v1',
                path: {
                    contractId: contractId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 체결본 - zip 다운로드
 * @returns any OK
 * @throws ApiError
 */
export function downloadZip(
    {
        requestBody,
    }: {
        requestBody: AlexContractZipDownloadCondition;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<any> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/contracts/zip/download/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 체결본 등록하기 V6
 * @returns AlexResponseContractCreateResponseDto OK
 * @throws ApiError
 */
export function createContractV6(
    {
        requestBody,
    }: {
        requestBody: AlexContractCreateFormV6;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AlexResponseContractCreateResponseDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/contracts/v6',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 체결본 무한 스크롤 검색 V2
 * @returns AlexResponseListContractScrollSearchResultV2 OK
 * @throws ApiError
 */
export function scrollSearchContractsV2(
    {
        requestBody,
        fetchSize,
        sortValue,
        tieBreaker,
    }: {
        requestBody: AlexContractScrollSearchConditionV2;
        /**
         * 가져올 데이터 수
         */
        fetchSize?: number;
        /**
         * 마지막 데이터의 sortValue - 조회 조건이 있으면 score 값, 없으면 createdAt 값
         */
        sortValue?: string;
        /**
         * 마지막 데이터의 tieBreaker
         */
        tieBreaker?: string;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AlexResponseListContractScrollSearchResultV2> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/contracts/scroll-search/v2',
                query: {
                    fetchSize: fetchSize,
                    sortValue: sortValue,
                    tieBreaker: tieBreaker,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 체결본 페이징 검색 V3 - FE에서 스크롤 방식으로 전환하면 사용하지 않을 예정
 * @returns AlexResponsePageContractPageSearchResultV3 OK
 * @throws ApiError
 */
export function pageSearchContractsV3(
    {
        requestBody,
        page,
        size = 10,
        sort,
    }: {
        requestBody: AlexContractPageSearchConditionV2;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AlexResponsePageContractPageSearchResultV3> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/contracts/page-search/v3',
                query: {
                    page: page,
                    size: size,
                    sort: sort,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 체결본 - 엑셀 다운로드 V2
 * @returns any OK
 * @throws ApiError
 */
export function excelContractsV2(
    {
        requestBody,
    }: {
        requestBody: AlexContractScrollSearchConditionV2;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<any> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/contracts/excel/v2',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 체결본 분석하기 V3
 * @returns AlexResponseContractAnalysisDtoV2 OK
 * @throws ApiError
 */
export function analyzeContractV3(
    {
        requestBody,
    }: {
        requestBody: AlexContractAnalyzerFormV2;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AlexResponseContractAnalysisDtoV2> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/contracts/analyze/v3',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 코드 목록 V2
 * @returns any OK
 * @throws ApiError
 */
export function getRepositoryCodesV2(_?: any, requestConfig?: RequestConfig): AxiosPromise<Record<string, Array<Record<string, any>>>> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/repository-codes/v2',
            }),
            requestConfig,
        ),
    );
}
/**
 * 체결본 상세 조회 V5
 * @returns AlexResponseContractDtoV5 OK
 * @throws ApiError
 */
export function getContractV5(
    {
        contractId,
    }: {
        contractId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AlexResponseContractDtoV5> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/{contractId}/v5',
                path: {
                    contractId: contractId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 체결본 PDF 다운로드
 * @returns any OK
 * @throws ApiError
 */
export function downloadContract(
    {
        contractId,
    }: {
        contractId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<any> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/{contractId}/download/v1',
                path: {
                    contractId: contractId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 체결본 상세 조회 by conhub contract ID V3
 * @returns AlexResponseContractDtoV5 OK
 * @throws ApiError
 */
export function getContractByConhubContractIdV3(
    {
        conhubContractId,
    }: {
        /**
         * Conhub 계약 ID
         */
        conhubContractId?: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AlexResponseContractDtoV5> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/v3',
                query: {
                    conhubContractId: conhubContractId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 체결본 PDF 다운로드 by conhub contract ID
 * @returns any OK
 * @throws ApiError
 */
export function downloadContractByConhubContractId(
    {
        conhubContractId,
    }: {
        /**
         * Conhub 계약 ID
         */
        conhubContractId?: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<any> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/download/by-conhub-contract-id/v1',
                query: {
                    conhubContractId: conhubContractId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 워크스페이스 계약 타입 조회
 * @returns AlexResponseListWorkspaceContractTypeDto OK
 * @throws ApiError
 */
export function getContractTypes(_?: any, requestConfig?: RequestConfig): AxiosPromise<AlexResponseListWorkspaceContractTypeDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contract-types/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 체결본 삭제
 * @returns AlexResponse OK
 * @throws ApiError
 */
export function deleteContract(
    {
        contractId,
    }: {
        contractId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AlexResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/contracts/{contractId}/v1',
                path: {
                    contractId: contractId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 체결본 라벨 조항 삭제
 * @returns AlexResponse OK
 * @throws ApiError
 */
export function deleteLabelClauseExtraction(
    {
        contractId,
        requestBody,
    }: {
        contractId: number;
        requestBody: AlexLabelClauseExtractionDeleteForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AlexResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/contracts/{contractId}/labelClauseExtraction/v1',
                path: {
                    contractId: contractId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 체결본 Bulk 등록 V1
 * @returns AlexResponse OK
 * @throws ApiError
 */
export function createBulkContractsV1(
    {
        requestBody,
    }: {
        requestBody: AlexContractBulkCreateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AlexResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/super-admin/contracts/bulk/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 통합 검색 (페이지)
 * @returns AlexResponsePageUnifiedSearchPageResult OK
 * @throws ApiError
 */
export function pageSearchUnified(
    {
        requestBody,
        page,
        size = 10,
        sort,
    }: {
        requestBody: AlexUnifiedSearchCondition;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AlexResponsePageUnifiedSearchPageResult> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/unified-search/page/v1',
                query: {
                    page: page,
                    size: size,
                    sort: sort,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 통합 검색 코드 목록
 * @returns any OK
 * @throws ApiError
 */
export function getUnifiedSearchCodes(_?: any, requestConfig?: RequestConfig): AxiosPromise<Record<string, Array<Record<string, any>>>> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/unified-search-codes/v1',
            }),
            requestConfig,
        ),
    );
}
