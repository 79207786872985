import { Node, mergeAttributes } from '@tiptap/core';
import { Fragment, type Node as ProseMirrorNode, type ResolvedPos } from '@tiptap/pm/model';
import { Plugin } from '@tiptap/pm/state';
import { SvelteNodeViewRenderer } from 'svelte-tiptap';

import { toastDanger } from '../../../basic/utils';
import BhsnBlockReferenceComponent from './BhsnBlockReferenceComponent.svelte';
import { BhsnReferenceExtension } from './BhsnReferenceExtension';
import { findParentByNodeTypeName } from '../editorUtils';

// getBhsnBlockReferenceItems 메서드만을 위한 타입
type BhsnBlockReferenceAttributes = Record<string, unknown>;

// blockReferenceNode가 갖는 attr. Node.create호출시 제네릭이나 기타방법으로 전달 불가하여 문서화를 위해 타입정의
export type BhsnBlockReferenceNodeAttributes = {
    'data-id': string;
    'data-name': string;
    'data-label': string;
    'data-value': string;
    'data-removable': boolean;
    'data-meta': string;
    'focused-input': boolean;
};

export type BhsnBlockReferenceItem = {
    id: string;
    name: string;
    content: Fragment;
    size: number;
    text: string;
};

export interface BhsnReferenceOptions {
    HTMLAttributes: Record<string, unknown>;
}
declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        blockReference: {
            // toggleBlockReference: () => ReturnType;
            updateBlockReferenceContent: (attributes: Array<{ id: string; value?: string | ProseMirrorNode[] }>) => ReturnType;
            focusedInputBlockReferenceContent: (id: string, scrollInto?: boolean, scrollIntoOption?: ScrollIntoViewOptions) => ReturnType;
            scrollIntoFocusedInputBlockReferenceContent: (id: string, option?: ScrollIntoViewOptions) => ReturnType;
            blurInputBlockReferenceContent: () => ReturnType;
            getBhsnBlockReferenceItems: (resolveCallback: (data: BhsnBlockReferenceItem[]) => void) => ReturnType;
            removeBlockReferences: (ids: string[]) => ReturnType;
            updateBlockReferenceAttributes: ({ filterFn, transform }: { filterFn: (node: ProseMirrorNode) => boolean; transform: (node: ProseMirrorNode) => ProseMirrorNode['attrs'] }) => ReturnType;
            wrapInBlockReference: (attributes?: BhsnBlockReferenceAttributes) => ReturnType;
        };
    }
}

export const BhsnBlockReferenceExtension = Node.create<BhsnReferenceOptions>({
    name: 'blockReference',
    group: 'block',
    content: 'block*',
    defining: true,
    isolating: true,
    selectable: true,
    inline: false,

    addOptions() {
        return {
            HTMLAttributes: {},
        };
    },

    addAttributes() {
        return {
            'data-id': {
                default: '',
            },
            'data-name': {
                default: '',
            },
            'focused-input': {
                default: undefined,
            },
            'data-label': {
                default: '',
            },
            'data-value': {
                default: '',
            },
            'data-removable': {
                default: false,
            },
            'data-meta': {
                default: undefined,
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: 'bhsn-block-reference',
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return ['bhsn-block-reference', mergeAttributes(HTMLAttributes), 0];
    },

    addCommands() {
        return {
            updateBlockReferenceContent:
                (attributes: Array<{ id: string; value?: string | ProseMirrorNode[] }>) =>
                ({ tr, state, dispatch }) => {
                    const { doc, schema } = state;
                    const blockReferenceNodeType = schema.nodes.blockReference;
                    const nodeInfos: Array<{ node: ProseMirrorNode; pos: number }> = [];

                    // blockReference 노드 중 전달된 attributes의 id와 일치하는 노드를 수집
                    doc.descendants((node, pos) => {
                        if (node.type === blockReferenceNodeType) {
                            if (attributes.some(attr => attr.id === node.attrs['data-id'] || attr.id === node.attrs['data-name'])) {
                                nodeInfos.push({ node, pos });
                            }
                        }
                    });

                    // 위치 문제를 피하기 위해 역순으로 처리
                    for (let i = nodeInfos.length - 1; i >= 0; i--) {
                        const { node, pos } = nodeInfos[i];
                        const { value } = attributes.find(attr => attr.id === node.attrs['data-id'] || attr.id === node.attrs['data-name']) || {};

                        let fragment: Fragment;
                        if (value) {
                            let nodes: ProseMirrorNode[] = [];
                            if (typeof value === 'string') {
                                const parts = value.split('\n');
                                // 인라인 노드를 임시로 모으기 위한 배열
                                const inlineNodes: ProseMirrorNode[] = [];
                                parts.forEach((part, index) => {
                                    if (part) {
                                        inlineNodes.push(schema.text(part, node.marks));
                                    }
                                    if (index < parts.length - 1) {
                                        inlineNodes.push(schema.nodes.hardBreak.create());
                                    }
                                });
                                // inline 요소들을 paragraph 노드로 감싸서 블록 레벨의 콘텐츠로 생성
                                const paragraph = schema.nodes.paragraph.create(null, Fragment.from(inlineNodes));
                                nodes.push(paragraph);
                            } else if (Array.isArray(value)) {
                                nodes = value.flatMap((rawNode: unknown) => {
                                    let pmNode: ProseMirrorNode;
                                    if (rawNode && typeof rawNode.toJSON === 'function') {
                                        pmNode = rawNode;
                                    } else {
                                        pmNode = schema.nodeFromJSON(rawNode);
                                    }
                                    // 만약 paragraph 노드의 inline 콘텐츠라면, paragraph 자체를 반환하도록 설정 (혹은 본문 전체를 블록으로 감싸는 추가 처리가 필요합니다)
                                    if (pmNode.type.name === 'paragraph' && pmNode.content.size) {
                                        // 여기서는 paragraph 노드를 그대로 사용하거나, 필요한 경우 다시 감싸는 처리를 할 수 있습니다.
                                        return [pmNode];
                                    }
                                    return [pmNode];
                                });
                                // 여러 개의 노드가 있을 경우, 각 노드 사이에 쉼표 텍스트 노드를 붙이는 부분은
                                // 상황에 따라 블록 콘텐츠 규격에 맞도록 별도의 처리가 필요합니다.
                                if (nodes.length > 1) {
                                    const nodesWithCommas: ProseMirrorNode[] = [];
                                    nodes.forEach((n, index) => {
                                        nodesWithCommas.push(n);
                                        if (index < nodes.length - 1) {
                                            // 쉼표 텍스트도 일반적으로 인라인 요소이므로, 이 경우에도 paragraph 등으로 감싸는 처리가 필요할 수 있음
                                            nodesWithCommas.push(schema.nodes.paragraph.create(null, schema.text(', ', [])));
                                        }
                                    });
                                    nodes = nodesWithCommas;
                                }
                            }
                            fragment = Fragment.from(nodes);
                        } else {
                            fragment = Fragment.empty;
                        }

                        const updatedNode = node.copy(fragment);
                        tr.replaceWith(pos, pos + node.nodeSize, updatedNode);
                    }

                    tr.setMeta('changedBhsnReference', true);
                    if (dispatch) dispatch(tr);
                    return true;
                },
            // Input 영역에서 data-id에 해당하는 block reference를 focus했을때 기존 focused-input를 모두 제거하고 해당 reference 에 focused-input attr을 추가합니다.
            focusedInputBlockReferenceContent:
                (id, scrollInto = false, scrollIntoOption = { behavior: 'smooth', block: 'center' }) =>
                ({ tr, view, state, dispatch }) => {
                    const { doc, schema } = state;
                    const blockReferenceNodeType = schema.nodes.blockReference;
                    let blockReferenceNodePosition: number | null = null;

                    // 선택한 reference 노드에만 'focused-input' 속성을 추가합니다.
                    doc.descendants((node, pos) => {
                        if (node.type === blockReferenceNodeType) {
                            const { attrs } = node;
                            if (id && id === node.attrs['data-id']) {
                                tr.setNodeMarkup(pos, null, {
                                    ...attrs,
                                    'focused-input': true,
                                });
                                if (blockReferenceNodePosition === null) blockReferenceNodePosition = pos;
                            } else {
                                tr.setNodeMarkup(pos, null, {
                                    ...attrs,
                                    'focused-input': undefined,
                                });
                            }
                        }
                    });

                    if (scrollInto && blockReferenceNodePosition !== null) {
                        const referenceNodeView = view.nodeDOM(blockReferenceNodePosition);
                        if (referenceNodeView) {
                            setTimeout(() => {
                                (referenceNodeView as Element).scrollIntoView(scrollIntoOption);
                            });
                        }
                    }
                    if (dispatch) dispatch(tr);
                    return true;
                },
            // Input 영역에서 data-id에 해당하는 block reference를 focus했을때 해당 reference로 스크롤합니다.
            scrollIntoFocusedInputBlockReferenceContent:
                (id, option = { behavior: 'smooth', block: 'center' }) =>
                ({ tr, view, state }) => {
                    if (!id) return false;
                    const { doc, schema } = state;
                    const blockReferenceNodeType = schema.nodes.blockReference;
                    let blockReferenceNodePosition: number | null = null;

                    doc.descendants((node, pos) => {
                        if (!blockReferenceNodePosition && node.type === blockReferenceNodeType && id === node.attrs['data-id']) {
                            blockReferenceNodePosition = pos;
                            return false;
                        }
                    });

                    if (blockReferenceNodePosition !== null) {
                        const referenceNodeView = view.nodeDOM(blockReferenceNodePosition);

                        if (referenceNodeView) {
                            setTimeout(() => {
                                (referenceNodeView as Element).scrollIntoView(option);
                            });
                        }
                    }
                    return true;
                },
            // Input 영역에서 data-id에 해당하는 reference를 blur했을때 기존 focused-input를 모두 제거
            blurInputBlockReferenceContent:
                () =>
                ({ tr, state, dispatch }) => {
                    const { doc, schema } = state;
                    const blockReferenceNodeType = schema.nodes.blockReference;

                    // 'focused-input' 속성을 모두 제거합니다.
                    doc.descendants((node, pos) => {
                        if (node.type === blockReferenceNodeType) {
                            const { attrs } = node;
                            const updatedAttrs = { ...attrs, 'focused-input': undefined };
                            tr.setNodeMarkup(pos, null, updatedAttrs);
                        }
                    });
                    if (dispatch) dispatch(tr);
                    return true;
                },
            getBhsnBlockReferenceItems:
                (resolveCallback = () => {}) =>
                ({ state }) => {
                    const { doc, schema } = state;
                    const referenceNodeType = schema.nodes.blockReference;
                    const references: BhsnBlockReferenceItem[] = [];

                    doc.descendants(node => {
                        if (node.type === referenceNodeType) {
                            references.push({
                                id: node.attrs['data-id'],
                                name: node.attrs['data-name'],
                                content: node.content,
                                size: node.content.size,
                                text: node.textContent,
                            });
                        }
                    });

                    resolveCallback(references);
                    return true;
                },
            removeBlockReferences:
                (ids: string[]) =>
                ({ editor, state, tr }) => {
                    const positions: Array<[number, number]> = [];

                    editor.state.doc.descendants((node, pos) => {
                        if (node.type === state.schema.nodes.blockReference && ids.includes(node.attrs['data-id'])) {
                            positions.push([pos, node.nodeSize]);
                        }
                    });

                    positions.toReversed().forEach(([pos, size]) => tr.delete(pos, pos + size));

                    return true;
                },
            updateBlockReferenceAttributes:
                ({ filterFn, transform }: { filterFn: (node: ProseMirrorNode) => boolean; transform: (node: ProseMirrorNode) => ProseMirrorNode['attrs'] }) =>
                ({ tr, state, dispatch }) => {
                    if (!filterFn) return true;

                    state.doc.descendants((node, pos) => {
                        if (node.type === state.schema.nodes.blockReference && filterFn(node)) {
                            const attributes = transform(node);
                            tr.setNodeMarkup(pos, null, { ...node.attrs, ...attributes });
                            if (dispatch) dispatch(tr);
                        }
                    });
                    return true;
                },

            wrapInBlockReference:
                (content?: BhsnBlockReferenceAttributes) =>
                ({ state: { selection, doc, schema }, tr, dispatch }) => {
                    const selectedSlice = doc.slice(selection.from, selection.to);
                    const paragraph = schema.nodes.paragraph.create(null, selectedSlice.content);

                    // content로부터 label과 meta 정보를 추출합니다.
                    const { label, value, name } = content ?? {};

                    const node = schema.nodes.blockReference.create(
                        {
                            'data-id': `${name}-${label}`,
                            'data-name': `${name}-${label}`,
                            'data-label': label,
                            'data-value': value,
                            'data-meta': JSON.stringify(content),
                            'data-removable': true,
                        },
                        Fragment.from(paragraph),
                    );

                    tr.replaceSelectionWith(node);

                    if (dispatch) dispatch(tr);
                    return true;
                },
        };
    },
    addNodeView() {
        return SvelteNodeViewRenderer(BhsnBlockReferenceComponent);
    },

    addProseMirrorPlugins() {
        return [
            // TODO: 독립된 Plugin으로 분리
            new Plugin({
                props: {
                    handleDrop: (view, event, slice, moved) => {
                        const STOP_DROP = true; // 드롭을 방지하기 위해 true를 반환합니다.

                        const coordinates = view.posAtCoords({
                            left: event.clientX,
                            top: event.clientY,
                        });
                        if (!coordinates) return STOP_DROP;

                        const pos = view.state.doc.resolve(coordinates.pos);
                        const data = event.dataTransfer?.getData('application/json');
                        const content = data && JSON.parse(data);
                        const isConditionBlock = content?.options?.length || slice.content.size > 1;

                        if (!moved && (!content || !isConditionBlock)) return STOP_DROP;

                        const draggingNodeTypeInsideEditor = slice.content.content[0]?.type?.name;

                        const isInsideBlockReference = findParentByNodeTypeName(pos, BhsnBlockReferenceExtension.name);
                        const isInsideReference = findParentByNodeTypeName(pos, BhsnReferenceExtension.name);

                        if (isInsideReference || isInsideBlockReference || (isInsideBlockReference && draggingNodeTypeInsideEditor === 'blockReference')) {
                            toastDanger('블록 내부에는 블록을 드롭할 수 없습니다.');
                            return STOP_DROP;
                        }
                        if (moved) return false; // 에디터 안에서 이동하는 경우는 드롭을 허용합니다.
                        if (!data) return STOP_DROP;

                        const referenceNodes: Array<ReturnType<typeof view.state.schema.nodes.blockReference.create>> = [];
                        for (const option of content.options) {
                            const node = view.state.schema.nodes.blockReference.create({
                                'data-id': content.id,
                                'data-name': `${content.name}-${option.label}`,
                                'data-label': option.label,
                                'data-value': option.value,
                                'data-removable': true,
                                'data-meta': JSON.stringify(content),
                            });

                            referenceNodes.push(node);
                        }

                        const { tr } = view.state;
                        tr.replaceWith(coordinates.pos, coordinates.pos, referenceNodes);

                        view.dispatch(tr);
                        return true;
                    },
                },
            }),
        ];
    },
});
